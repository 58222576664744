define('client/components/stacked-chart', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		didInsertElement: function didInsertElement() {
			var self = this;
			$(function () {
				$('#chart_home').highcharts({
					colors: ["#28821A", "#880612"],
					chart: {
						type: 'column'
					},
					title: {
						text: self.title
					},
					xAxis: {
						categories: ['0 - 1 Uhr', '1 - 2 Uhr', '2 - 3 Uhr', '3 - 4 Uhr', '4 - 5 Uhr', '5 - 6 Uhr', '6 - 7 Uhr', '7 - 8 Uhr', '8 - 9 Uhr', '9 - 10 Uhr', '10 - 11 Uhr', '11 - 12 Uhr', '12 - 13 Uhr', '13 - 14 Uhr', '14 - 15 Uhr', '15 - 16 Uhr', '16 - 17 Uhr', '17 - 18 Uhr', '18 - 19 Uhr', '19 - 20 Uhr', '20 - 21 Uhr', '21 - 22 Uhr', '22 - 23 Uhr', '23 - 0 Uhr']
					},
					yAxis: {
						min: 0,
						title: {
							text: 'Gesamt'
						},
						stackLabels: {
							enabled: false,
							style: {
								fontWeight: 'bold',
								color: Highcharts.theme && Highcharts.theme.textColor || 'gray'
							}
						}
					},
					legend: {
						align: 'right',
						x: -30,
						verticalAlign: 'top',
						y: 25,
						floating: true,
						backgroundColor: Highcharts.theme && Highcharts.theme.background2 || 'white',
						borderColor: '#CCC',
						borderWidth: 1,
						shadow: false
					},
					tooltip: {
						formatter: function formatter() {
							return '<b>' + this.x + '</b><br/>' + this.series.name + ': ' + this.y + '<br/>' + 'Total: ' + this.point.stackTotal;
						}
					},
					plotOptions: {
						column: {
							stacking: 'normal',
							dataLabels: {
								enabled: true,
								color: Highcharts.theme && Highcharts.theme.dataLabelsColor || 'white',
								style: {
									textShadow: '0 0 3px black'
								}
							}
						}
					},
					series: self.data
				});
			});
		}
	});
});