define('client/controllers/voiceports/extension/telefon/buttons', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin', 'cecc/mixins/model-array'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin, _ceccMixinsModelArray) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
		rollback: true,
		convert: function convert(model) {
			_ember['default'].$.each(model, function (k, v) {
				if (v === true) {
					model[k] = '1';
				} else if (v === false) {
					model[k] = '0';
				}
			});

			return model;
		},
		buttonIds: [],
		button: false,
		newButton: { args: {} },
		functionNames: [{ name: 'Team', id: "team" }, { name: 'Kurzwahl', id: "autodial" }, { name: 'Heranholen', id: "call-pkup" }, { name: 'Leitungstasten', id: "call-appr" }, { name: 'Rufweiterleitung', id: "call-fwd" }, { name: 'Rückruf', id: "auto-cback" }, { name: 'Nicht Stören', id: "dn-dst" }, { name: 'EC500', id: "ec500" }],
		modules: (function () {
			var model = this.get('model');
			var modules = [];
			var count = 0;

			if (typeof model.buttonModules !== 'undefined' && model.buttonModules !== null) {
				count = model.buttonModules;
			}

			modules.push({ name: 'Telefon', id: 0 });

			for (var i = 1; i <= count; i++) {
				modules.push({ name: 'Button Modul ' + i, id: i });
			}

			return modules;
		}).property('model.buttonModules'),
		updateShowIds: (function () {
			var button = this.get('button');
			this.updateIds(button.position, button.module);
		}).observes('button.module'),
		updateCreateIds: (function () {
			var button = this.get('newButton');
			var module = typeof button.module !== 'undefined' ? button.module : 0;

			var buttonIds = [];
			var buttons = this.get('buttons');
			for (var i = 1; i < 25; i++) {
				buttonIds.push({ id: i, key: i });
			}

			_ember['default'].$.each(buttons, function (k, v) {
				var found = buttonIds.findBy('id', v.position);
				if (found && module === v.module) {
					buttonIds.removeObject(found);
				}
			});

			this.set('buttonIds', buttonIds);
			this.set('newButton.position', null);
		}).observes('newButton.module'),
		updateIds: function updateIds(except, module) {
			except = except || false;
			module = module || 0;

			var buttonIds = [];
			var buttons = this.get('buttons');
			for (var i = 1; i < 25; i++) {
				buttonIds.push({ id: i, key: i });
			}

			if (except !== false) {
				_ember['default'].$.each(buttons, function (k, v) {
					var found = buttonIds.findBy('id', v.position);
					if (found && except !== v.position && module === v.module) {
						buttonIds.removeObject(found);
					}
				});
			}

			this.set('buttonIds', buttonIds);
		},
		getButtons: function getButtons() {
			var model = this.get('model');
			var self = this;

			this.api.findAll('extension/' + model.id + '/button').then(function (r) {
				_ember['default'].$.each(r.results, function (k, v) {
					if (typeof v.args === "undefined") {
						r.results[k].args = {};
					}
				});
				var buttons = _ceccMixinsModelArray['default'].create().make(r.results);
				self.set('buttons', buttons);
			});
		},
		actions: {
			showCreateButton: function showCreateButton() {
				this.updateCreateIds();
				this.set('showCreateButton', !this.get('showCreateButton'));
				this.set('newButton', { args: {} });
				this.set('button', false);
				_ember['default'].$('.detail-content').scrollTop(0);
			},
			showButton: function showButton(button) {
				this.updateIds(button.position, button.module);
				this.set('button', this.data.parse(button));
				this.set('update', Date.now());
				this.set('showCreateButton', false);
				_ember['default'].$('.detail-content').scrollTop(0);
			},
			cancel: function cancel() {
				this.set('button', false);
				this.set('newButton', { args: {} });
			},
			createButton: function createButton() {
				var self = this;
				var model = this.get('model');
				var button = this.get('newButton');
				button.id = button.module + '-' + button.position;

				if (button.id == "0-1" || button.id == "0-2" || button.id == "0-61") {
					this.notify.error('Button gesperrt');
					self.set('button', false);
					return;
				}

				this.api.create('extension/' + model.id + '/button', { data: JSON.stringify(button) }).then(function (r) {
					self.notify.info(r.message);
					self.getButtons();
					self.set('showCreateButton', false);
					self.set('newButton', {});
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			saveButton: function saveButton() {
				var self = this;
				var model = this.get('model');
				var button = this.get('button');

				if (button.id == "0-1" || button.id == "0-2" || button.id == "0-61") {
					this.notify.error('Button gesperrt');
					self.set('button', false);
					return;
				}

				if (typeof button.functionName === 'undefined') {
					this.notify.info('Function has not been changed.');
					this.set('button', false);
					return;
				}

				this.api.save('extension/' + model.id + '/button', button.id, { data: JSON.stringify(button) }).then(function (r) {
					self.notify.info(r.message);
					self.set('button', false);
					self.getButtons();
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			},
			removeButton: function removeButton() {
				var self = this;
				var model = this.get('model');
				var button = this.get('button');
				button.id = button.module + '-' + button.position;

				if (button.id == "0-1" || button.id == "0-2" || button.id == "0-61") {
					this.notify.error('Button gesperrt');
					self.set('button', false);
					return;
				}

				this.api.remove('extension/' + model.id + '/button/' + button.id).then(function (r) {
					self.notify.info(r.message);
					self.set('button', false);
					self.get('buttons').removeObject(button);
					self.updateIds();
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			}
		}
	});
});