define('client/helpers/just-date', ['exports', 'cecc/helpers/just-date'], function (exports, _ceccHelpersJustDate) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersJustDate['default'];
    }
  });
  Object.defineProperty(exports, 'justDate', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersJustDate.justDate;
    }
  });
});