define('client/helpers/status-label', ['exports', 'cecc/helpers/status-label'], function (exports, _ceccHelpersStatusLabel) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersStatusLabel['default'];
    }
  });
  Object.defineProperty(exports, 'statusLabel', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersStatusLabel.statusLabel;
    }
  });
});