define('client/routes/queue', ['exports', 'ember', 'cecc/mixins/list-route-mixin'], function (exports, _ember, _ceccMixinsListRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsListRouteMixin['default'], {
		control: 'queue',
		afterModel: function afterModel(model) {
			_ember['default'].$.each(model, function (k, v) {
				try {
					var data = JSON.parse(v.arguments);
					_ember['default'].set(v, 'data', data);
					_ember['default'].set(v.data, 'Data', JSON.parse(data.Data));
				} catch (e) {}
			});
		}
	});
});