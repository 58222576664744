define('client/controllers/voiceports/create/index', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
		resource: 'extension',
		root: 'voiceports',
		types: [{ id: '9611', name: 'Deskphone' },
		//{id: 'vdn', name: 'Virtual'},
		//{id: 'analog', name: 'Analog'},
		{ id: 'meetme', name: 'MeetMe' }],
		isDeskphone: (function () {
			var model = this.get('model');
			if (model.type == '9611') {
				return true;
			}
			return false;
		}).property('model.type'),
		isVDN: (function () {
			var model = this.get('model');
			if (model.type == 'vdn') {
				return true;
			}
			return false;
		}).property('model.type'),
		isAnalog: (function () {
			var model = this.get('model');
			if (model.type == 'analog') {
				return true;
			}
			return false;
		}).property('model.type'),
		isMeetMe: (function () {
			var model = this.get('model');
			if (model.type == 'meetme') {
				return true;
			}
			return false;
		}).property('model.type'),
		actions: {
			create: function create() {
				var self = this;
				var model = this.get('model');
				model.id = model.location_prefix + '' + model.extension;
				model.ec500status = 0;
				model.ec500Subscribed = 0;
				var ex = model.extension;

				if (!ex.match(/^\d+$/)) {
					this.notify.error('Durchwahl darf nur aus Zahlen bestehen!');
					return;
				}

				if (ex.length <= 1 || ex.length >= 5) {
					this.notify.error('Durchwahl darf maximal aus 2-4 Zahlen bestehen!');
					return;
				}

				if (model.id.length !== 6) {
					this.notify.error('Kombination aus der Location ID und der Durchwahl muss 6 Zeichen lang sein!');
					return;
				}

				this.api.create(this.get('resource'), { data: JSON.stringify(model) }).then(function (r) {
					if (r.status === "success") {
						self.notify.info(r.message);
						self.transitionToRoute('voiceports');
					} else {
						self.notify.error(r.message);
					}
				})['catch'](function (r) {
					self.notify.error(r.responseJSON.message);
				});
			}
		}
	});
});