define('client/components/extension-button', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: '',
		args: {},
		extension: null,
		isVoiceMail: (function () {
			var model = this.get('model');
			if (model.id === 61) {
				return true;
			}

			return false;
		}).property('model'),
		rings: [{ id: 'd', name: 'verzögert' }, { id: 'a', name: 'verkürzt' }, { id: 'n', name: 'kein klingeln' }, { id: 'r', name: 'klingelt mit' }],
		ring: (function () {
			var ring = this.get('rings').findBy('id', this.get('args.second'));
			if (ring) {
				return ring.name;
			}
			return '';
		}).property('args.second'),
		call_pkup: [{ id: 'h', name: 'Half-Ring' }, { id: 'i', name: 'Intercom-Ring' }],
		call_ring: (function () {
			var ring = this.get('call_pkup').findBy('id', this.get('args.second'));
			if (ring) {
				return ring.name;
			}
			return '';
		}).property('args.second'),
		isTeam: (function () {
			var model = this.get('model');
			return model.functionName === 'team';
		}).property('model.functionName'),
		isAutoDial: (function () {
			var model = this.get('model');
			return model.functionName === 'autodial';
		}).property('model.functionName'),
		isCallFwd: (function () {
			var model = this.get('model');
			return model.functionName === 'call-fwd';
		}).property('model.functionName'),
		isCallPkup: (function () {
			var model = this.get('model');
			return model.functionName === 'call-pkup';
		}).property('model.functionName'),
		updateArgs: (function () {
			this.set('model.args', this.unparseElements(this.get('args')));
		}).observes('args', 'args.first', 'args.second', 'args.third'),

		updateArgsAfterFunctionChange: (function () {}).observes('model.functionName'),

		changedExtension: (function () {
			this.set('args.first', this.get('extension.id'));
		}).observes('extension'),

		parseElements: function parseElements(args) {
			var newargs = {};

			if (typeof args === "undefined") {
				return newargs;
			}
			if (typeof args[0] !== "undefined") {
				newargs.first = args[0];
			}
			if (typeof args[1] !== "undefined") {
				newargs.second = args[1];
			}
			if (typeof args[2] !== "undefined") {
				newargs.third = args[2];
			}

			console.log(newargs);

			return newargs;
		},
		unparseElements: function unparseElements(args) {
			var newargs = {};

			if (typeof args === "undefined") {
				return newargs;
			}
			if (typeof args.first !== "undefined") {
				newargs[0] = args.first;
			}
			if (typeof args.second !== "undefined") {
				newargs[1] = args.second;
			}
			if (typeof args.third !== "undefined") {
				newargs[2] = args.third;
			}

			return newargs;
		},
		didInsertElement: function didInsertElement() {
			var args = this.get('model.args');
			var self = this;
			if (this.get('model.functionName') == 'team') {
				this.api.query('extension', { id: args[0] }).then(function (data) {
					self.set('extension', data[0]);
				});
			}
			this.set('args', this.parseElements(args));
		},
		updateComponent: (function () {
			var args = this.get('model.args');
			var self = this;
			if (this.get('model.functionName') == 'team') {
				this.api.query('extension', { id: args[0] }).then(function (data) {
					self.set('extension', data.results[0]);
				});
			}
			this.set('args', this.parseElements(args));
		}).observes('model.id'),
		actions: {
			queryExtensions: function queryExtensions(query, deferred) {
				this.api.query('extension', { id: { like: query.term, op: "OR" }, name: { like: query.term, op: "OR" } }, [], 1, 1000000).then(function (data) {
					deferred.resolve({ data: data.results, more: false });
				}, deferred.reject);
			}
		}
	});
});