define('client/helpers/cor-name', ['exports', 'ember'], function (exports, _ember) {
    exports.corName = corName;

    function corName(params /*, hash*/) {
        var id = params[0];
        var res;

        switch (id) {
            case "1":
                res = 'Intern / Notruf';
                break;
            case "2":
                res = 'Ortsbereich';
                break;
            case "4":
                res = 'National';
                break;
            case "5":
                res = 'Europa';
                break;
            case "6":
                res = 'International';
                break;
            case "7":
                res = 'International und Service';
                break;
            default:
                res = id;

        }

        return new _ember['default'].String.htmlSafe(res);
    }

    exports['default'] = _ember['default'].Helper.helper(corName);
});