define('client/helpers/status-name', ['exports', 'cecc/helpers/status-name'], function (exports, _ceccHelpersStatusName) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersStatusName['default'];
    }
  });
  Object.defineProperty(exports, 'statusName', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersStatusName.statusName;
    }
  });
});