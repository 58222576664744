define('client/controllers/login', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		actions: {
			login: function login() {

				var self = this;
				var portal = 'customerportal';
				//var cookie = Cookies.get('lang');

				if (window.location.href.indexOf('my.') != -1) {
					portal = 'myportal';
				}

				var mail = this.get('mail');
				var pass = this.get('pass');
				var data = {
					email: mail,
					password: pass,
					portal: portal
				};

				this.api.post('login', data).then(function (r) {

					if (r.status === "success") {

						self.set('data.overlay', 'layouts/login');
						self.set('data.user', r.results);
						self.set('data.online', true);

						// if(r.data.language !== '' && !cookie){
						// 	self.set('data.lang', r.data.language);
						// 	self.set('i18n.locale', r.data.language);
						// 	moment.locale(r.data.language);
						// 	numeral.language(r.data.language);
						// }
						//self.io.connect(self, r.results);

						var transition = self.get('data.transition');

						if (transition && transition.targetName != 'login.index') {
							self.set('data.transition', null);
							transition.retry();
						} else {
							self.transitionToRoute('home');
						}
					} else {
						self.notify.error(r.message);
					}
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			}
		}
	});
});