define('client/controllers/task', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		isSuccess: (function () {
			var model = this.get('model');
			if (model.status === "success") {
				return true;
			}
			return false;
		}).property('model.status')
	});
});