define('client/routes/voiceports/extension/telefon/buttons', ['exports', 'ember', 'cecc/mixins/subdetail-route-mixin', 'cecc/mixins/model-array'], function (exports, _ember, _ceccMixinsSubdetailRouteMixin, _ceccMixinsModelArray) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsSubdetailRouteMixin['default'], {
		afterModel: function afterModel(model) {
			var self = this;

			this.api.findAll('extension/' + model.id + '/button').then(function (r) {
				var buttons = _ceccMixinsModelArray['default'].create().make(r.results);

				self.controllerFor('voiceports.extension.telefon.buttons').set('buttons', buttons);
			});
			this._super(model);
		}
	});
});