define('client/helpers/status-color', ['exports', 'cecc/helpers/status-color'], function (exports, _ceccHelpersStatusColor) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersStatusColor['default'];
    }
  });
  Object.defineProperty(exports, 'statusColor', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersStatusColor.statusColor;
    }
  });
});