define('client/initializers/cache', ['exports', 'cecc/initializers/cache'], function (exports, _ceccInitializersCache) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersCache['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersCache.initialize;
    }
  });
});