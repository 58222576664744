define('client/routes/voiceports/extension', ['exports', 'ember', 'cecc/mixins/detail-route-mixin', 'cecc/mixins/model'], function (exports, _ember, _ceccMixinsDetailRouteMixin, _ceccMixinsModel) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsDetailRouteMixin['default'], {
		control: 'voiceports',
		model: function model(params) {
			var self = this;
			var query;
			var isMyPortal = window.location.href.indexOf('my.') != -1 ? true : false;

			if (isMyPortal) {
				query = { id: params.id, user_id: this.data.user.id };
			} else {
				query = { id: params.id };
			}

			return this.api.query('extension', query, ['locations', 'pickupgroups', 'users']).then(function (r) {
				if (r.results.length === 1) {
					return _ceccMixinsModel['default'].create().make(r.results[0]);
				} else {
					self.transitionTo('voiceports');
				}
			});
		},
		afterModel: function afterModel(model) {
			if (model && typeof model.voiceFaxStatus !== 'boolean') {
				_ember['default'].set(model, 'voiceFaxStatus', parseInt(model.voiceFaxStatus) === 1);
				_ember['default'].set(model, 'ec500status', parseInt(model.ec500status) === 1);
				_ember['default'].set(model, 'ec500Subscribed', parseInt(model.ec500Subscribed) === 1);
				_ember['default'].set(model, 'sipSubscribed', parseInt(model.sipSubscribed) === 1);
				_ember['default'].set(model, 'messagingSubscribed', parseInt(model.messagingSubscribed) === 1);
				_ember['default'].set(model, 'unconditionalInternalActive', parseInt(model.unconditionalInternalActive) === 1);
				_ember['default'].set(model, 'unconditionalExternalActive', parseInt(model.unconditionalExternalActive) === 1);
				_ember['default'].set(model, 'busyInternalActive', parseInt(model.busyInternalActive) === 1);
				_ember['default'].set(model, 'busyExternalActive', parseInt(model.busyExternalActive) === 1);
				_ember['default'].set(model, 'noReplyInternalActive', parseInt(model.noReplyInternalActive) === 1);
				_ember['default'].set(model, 'noReplyExternalActive', parseInt(model.noReplyExternalActive) === 1);
			}

			this.cache.setCache('extension', model);
			this._super(model);
		}
	});
});