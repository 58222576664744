define('client/routes/home', ['exports', 'ember', 'cecc/mixins/authentication'], function (exports, _ember, _ceccMixinsAuthentication) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsAuthentication['default'], {
		model: function model() {
			return {};
		},
		afterModel: function afterModel() {
			var self = this;

			// locations
			this.api.count('location', {}, [], 1, 10000).then(function (r) {
				self.controllerFor('home').set('locations', r.count);
			});

			// pickupgroups

			this.api.count('pickupGroups', {}, [], 1, 10000).then(function (r) {
				self.controllerFor('home').set('pickupgroups', r.count);
			});

			// extensions

			this.api.count('extension', { active: 1 }, [], 1, 10000).then(function (r) {
				self.controllerFor('home').set('extensions', r.count);
			});

			// reports month

			var from = moment().format('YYYY-MM') + '-01 00:00:00';
			var to = moment(from).add(1, 'month').format('YYYY-MM-DD') + ' 00:00:00';

			this.api.count('callreport', { 'callstate': { 'eq': 'MISSED', 'op': 'AND' }, 'starttime': { 'gt': from, 'op': 'AND' }, 'AND': { 'starttime': { 'lt': to, 'op': 'AND' } } }, [], 1, 1000000, 'ucid').then(function (r) {
				self.controllerFor('home').set('reports.month.missed', r.count);
			});

			this.api.count('callreport', { 'callstate': { 'eq': 'ACCEPTED', 'op': 'AND' }, 'starttime': { 'gt': from, 'op': 'AND' }, 'AND': { 'starttime': { 'lt': to, 'op': 'AND' } } }, [], 1, 1000000, 'ucid').then(function (r) {
				self.controllerFor('home').set('reports.month.accepted', r.count);
			});

			// reports today

			from = moment().format('YYYY-MM-DD') + ' 00:00:00';
			to = moment().add(1, 'd').format('YYYY-MM-DD') + ' 00:00:00';

			this.api.count('callreport', { 'callstate': { 'eq': 'MISSED', 'op': 'AND' }, 'starttime': { 'gt': from, 'op': 'AND' }, 'AND': { 'starttime': { 'lt': to, 'op': 'AND' } } }, [], 1, 1000000, 'ucid').then(function (r) {
				self.controllerFor('home').set('reports.today.missed', r.count);
			});

			this.api.count('callreport', { 'callstate': { 'eq': 'ACCEPTED', 'op': 'AND' }, 'starttime': { 'gt': from, 'op': 'AND' }, 'AND': { 'starttime': { 'lt': to, 'op': 'AND' } } }, [], 1, 1000000, 'ucid').then(function (r) {
				self.controllerFor('home').set('reports.today.accepted', r.count);
			});

			// extension types

			this.api.count('extension', { type: { 'in': ["1608", "1616", "4610", "4612CL", "4620", "4621", "6408", "6408D+", "9608", "9608SIP", "9608SIPCC", "9611", "9611SIP", "9620", "9621", "9620SIP", "9630", "9630SIP", "9640", "9640SIP", "9641", "9650", "9650SIP", "9408"], 'op': 'and' }, active: { 'eq': 1 } }, [], 1, 10000).then(function (r) {
				self.controllerFor('home').set('deskphones', r.count);
			});

			this.api.count('extension', { type: { 'in': ["vdn"] }, active: { 'eq': 1 } }, [], 1, 10000).then(function (r) {
				self.controllerFor('home').set('virtuell', r.count);
			});

			this.api.count('extension', { type: { 'in': ["2500", "CallrID"] }, active: { 'eq': 1 } }, [], 1, 10000).then(function (r) {
				self.controllerFor('home').set('analog', r.count);
			});

			this.api.count('extension', { type: { 'in': ["agent"] }, active: { 'eq': 1 } }, [], 1, 10000).then(function (r) {
				self.controllerFor('home').set('agent', r.count);
			});

			this.api.count('extension', { messagingSubscribed: { 'eq': ["1"] }, active: { 'eq': 1 } }, [], 1, 10000).then(function (r) {
				self.controllerFor('home').set('messagingSubscribed', r.count);
			});
		}
	});
});