define('client/controllers/reset', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		queryParams: ['code'],
		actions: {
			reset: function reset() {
				var self = this;

				this.api.post("user/resetPassword", { password: this.get('password'), code: this.get('code') }).then(function (res) {
					self.notify.error(res.message);
					self.transitionToRoute('login');
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			}
		}
	});
});