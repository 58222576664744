define('client/controllers/voiceports/extension/telefon/index', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
		rollback: true,
		convert: function convert(model) {
			_ember['default'].$.each(model, function (k, v) {
				if (v === true) {
					model[k] = '1';
				} else if (v === false) {
					model[k] = '0';
				}
			});

			return model;
		},
		actions: {
			save: function save() {
				var model = this.get('model');
				var self = this;

				if (model.h323Pin.length > 0) {

					if (!model.h323Pin.match(/^\d+$/)) {
						this.notify.error('Kennwort darf nur aus Zahlen bestehen!');
						return;
					}

					if (model.h323Pin.length > 8) {
						this.notify.error('Kennwort darf maximal aus 8 Zahlen bestehen!');
						return;
					}
				}

				var compared = this.cache.compare('extension', model);

				this.api.save('extension', model.id, { data: JSON.stringify(this.convert(compared)) }).then(function (r) {
					self.notify.info(r.message);
					if (typeof model.save !== 'undefined') {
						model.save();
					}
					self.set('edit', false);
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			}
		}
	});
});