define('client/routes/task', ['exports', 'ember', 'cecc/mixins/authentication'], function (exports, _ember, _ceccMixinsAuthentication) {
  exports['default'] = _ember['default'].Route.extend(_ceccMixinsAuthentication['default'], {
    model: function model(params) {
      return this.api.save('task/confirm', params.token).then(function (r) {
        return r;
      })['catch'](function (r) {
        return r.responseJSON;
      });
    }
  });
});