define('client/helpers/module-name', ['exports', 'ember'], function (exports, _ember) {
	exports.moduleName = moduleName;

	function moduleName(params /*, hash*/) {
		var module = params[0];
		if (module > 0) {
			return 'Modul ' + module;
		}
		return 'Telefon';
	}

	exports['default'] = _ember['default'].Helper.helper(moduleName);
});