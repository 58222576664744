define('client/controllers/application', ['exports', 'ember', 'cecc/mixins/model-array'], function (exports, _ember, _ceccMixinsModelArray) {
	exports['default'] = _ember['default'].Controller.extend({
		image: (function () {
			var user = this.get('data.user');
			if (user.image) {
				return 'http://assets.comulus.net/uploads/' + user.image;
			} else {
				return 'assets/img/noimage.png';
			}
		}).property('data.user.image'),
		isMyPortal: (function () {
			if (window.location.href.indexOf('my.') != -1) {
				return true;
			}
			return false;
		}).property(),
		actions: {
			login: function login() {

				var self = this;
				var portal = 'customerportal';

				if (window.location.href.indexOf('my.') != -1) {
					portal = 'myportal';
				}

				var mail = this.get('mail');
				var pass = this.get('pass');
				var data = {
					email: mail,
					password: pass,
					portal: portal
				};

				this.api.post('login', data).then(function (r) {

					if (r.status === "success") {

						//self.io.connect(self, r.results);
						var transition = self.get('data.transition');
						if (transition) {
							self.get('data.transition', null);
							transition.retry();
						}
						self.get('login-modal').hide();
					} else {
						self.notify.error(r.message);
					}
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			}
		},
		loadModel: (function () {
			var self = this;

			if (!this.data.online) {
				return false;
			}

			this.api.query('extension', { user_id: this.data.user.id }, ['locations', 'pickupgroups']).then(function (r) {
				self.set('model', _ceccMixinsModelArray['default'].create().make(r.results));
			});
		}).observes('data.online')
	});
});