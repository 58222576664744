define('client/components/queue-counter', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		tagName: 'span',
		classNames: ['badge', 'pull-right', 'queue-counter'],
		count: 0,
		update: function update() {
			var self = this;
			this.api.count('job', { status: { 'in': ['pending'] } }, [], 1, 10000).then(function (r) {
				self.set('count', r.result);
			});
		},
		didInsertElement: function didInsertElement() {
			var self = this;
			this.update();
			this.set('interval', setInterval(function () {
				self.update();
			}, 15000));
		},
		willDestroyElement: function willDestroyElement() {
			clearInterval(this.interval);
		}
	});
});