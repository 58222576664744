define('client/controllers/home', ['exports', 'ember'], function (exports, _ember) {
				exports['default'] = _ember['default'].Controller.extend({
								extensions: ' ',
								pickupgroups: ' ',
								locations: ' ',
								reports: {
												all: {},
												today: {},
												week: {},
												month: {}
								},
								isMyPortal: (function () {
												if (window.location.href.indexOf('my.') != -1) {
																return true;
												}
												return false;
								}).property()
				});
});