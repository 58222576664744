define("client/helpers/button-function-name", ["exports", "ember"], function (exports, _ember) {
    exports.buttonFunctionName = buttonFunctionName;

    function buttonFunctionName(params /*, hash*/) {

        var id = params[0];
        var res;

        switch (id) {
            case "team":
                res = "Team";
                break;
            case 'autodial':
                res = "Kurzwahl";
                break;
            case 'call-pkup':
                res = "Heranholen";
                break;
            case 'auto-cback':
                res = "Rückruf";
                break;
            case "call-appr":
                res = 'Leitungstaste';
                break;
            case "call-fwd":
                res = 'Rufweiterleitung';
                break;
            case "dn-dst":
                res = 'Nicht Stören';
                break;
            case "ec500":
                res = 'EC500';
                break;
            case "voice-mail":
                res = 'Voice Mail';
                break;
            default:
                res = id;

        }

        return new _ember["default"].String.htmlSafe(res);
    }

    exports["default"] = _ember["default"].Helper.helper(buttonFunctionName);
});