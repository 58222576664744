define('client/helpers/only-date', ['exports', 'cecc/helpers/only-date'], function (exports, _ceccHelpersOnlyDate) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersOnlyDate['default'];
    }
  });
  Object.defineProperty(exports, 'onlyDate', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersOnlyDate.onlyDate;
    }
  });
});