define('client/routes/voiceports/create', ['exports', 'ember', 'cecc/mixins/detail-route-mixin'], function (exports, _ember, _ceccMixinsDetailRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsDetailRouteMixin['default'], {
		control: 'voiceports',
		model: function model() /*params*/{
			return {
				type: null,
				extension: '',
				name: ''
			};
		},
		afterModel: function afterModel(model /*, transition*/) {
			var self = this;
			this.api.query('location', {}, [], 1, 100000).then(function (r) {
				self.controllerFor('voiceports.create.index').set('locations', r.results);
			});
			this._super(model);
		}
	});
});