define('client/routes/voiceports', ['exports', 'ember', 'cecc/mixins/list-route-mixin'], function (exports, _ember, _ceccMixinsListRouteMixin) {
    exports['default'] = _ember['default'].Route.extend(_ceccMixinsListRouteMixin['default'], {
        control: 'voiceports',
        afterModel: function afterModel(model) {
            var self = this;

            // locations
            this.api.query('location', {}, [], 1, 100000).then(function (r) {
                self.controllerFor('voiceports').set('locations', r.results);
            });

            // locations
            this.api.query('project', {}, [], 1, 100000).then(function (r) {
                self.controllerFor('voiceports').set('projects', r.results);
            });

            this._super(model);
        },
        resetController: function resetController(controller, isExiting /*, transition*/) {
            if (isExiting) {
                controller.set('page', 1);
                controller.set('query', '');
            }
        }
    });
});