define('client/controllers/voiceports/extension/index', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin) {
				exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
								root: 'voiceports',
								resource: 'extension',
								rollback: true,
								cors: [{ name: 'Intern / Notruf', id: "1" }, { name: 'Ortsbereich', id: "2" }, { name: 'National', id: "4" }, { name: 'Europa', id: "5" }, { name: 'International', id: "6" }, { name: 'International und Service', id: "7" }],
								isMyPortal: (function () {
												if (window.location.href.indexOf('my.') != -1) {
																return true;
												}
												return false;
								}).property()
				});
});