define('client/controllers/voiceports/extension/team', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
		rollback: true,
		actions: {
			save: function save() {
				this.save();
			}
		},
		updatePickupGroup: (function () {
			var model = this.get('model');
			if (model.pickupgroups) {
				this.set('model.pickupgroup_id', model.pickupgroups.id);
			} else {
				this.set('model.pickupgroup_id', '');
			}
		}).observes('model.pickupgroups'),
		save: function save() {
			var self = this;
			var model = this.get('model');
			var compared = this.cache.compare('extension', model);

			this.api.save('extension', model.id, { data: JSON.stringify(compared) }).then(function (r) {
				self.set('edit', false);
				self.cache.setCache('extension', model);
				self.notify.info(r.message);

				if (typeof model.save !== 'undefined') {
					model.save();
				}

				_ember['default'].$.each(self.pickupgroups, function (k, v) {
					if (v.id === model.pickupgroup_id) {
						self.set('model.pickupgroups', v);
					}
				});
			})['catch'](function (r) {
				self.notify.error(r.message);
			});
		}
	});
});