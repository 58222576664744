define('client/helpers/html', ['exports', 'ember'], function (exports, _ember) {
	exports.html = html;

	function html(params /*, hash*/) {
		if (params[0] !== undefined) {
			return params[0].replace(/\n/g, "<br>");
		}
		return '';
	}

	exports['default'] = _ember['default'].Helper.helper(html);
});