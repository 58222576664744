define('client/routes/index', ['exports', 'ember', 'cecc/mixins/no-authentication'], function (exports, _ember, _ceccMixinsNoAuthentication) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsNoAuthentication['default'], {
		beforeModel: function beforeModel() {
			this.transitionTo('login');
		},
		actions: {
			willTransition: function willTransition() {
				this.transitionTo('login');
			}
		}
	});
});