define('client/initializers/data', ['exports', 'cecc/initializers/data'], function (exports, _ceccInitializersData) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersData['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersData.initialize;
    }
  });
});