define('client/controllers/voiceports/extension/sip', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
		rollback: true,
		generatePassword: function generatePassword() {

			var length = 8,
			    charset = "abcdefghijklnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789+-._*#!",
			    retVal = "";
			for (var i = 0, n = charset.length; i < length; ++i) {
				retVal += charset.charAt(Math.floor(Math.random() * n));
			}

			if (/(?=.{8,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/.test(retVal)) {
				return retVal;
			} else {
				return this.generatePassword();
			}
		},
		actions: {
			generate: function generate() {
				this.set('model.sipPassword', this.generatePassword());
			},
			save: function save() {

				var self = this;
				var cached = this.cache.getCache('extension');
				var model = this.get('model');

				if (model.sipPassword === undefined && model.sipSubscribed) {
					this.notify.error('Bitte ein Passwort generieren!');
					return;
				}

				var data = {
					sipPassword: model.sipPassword
				};

				if (cached.sipSubscribed && !model.sipSubscribed) {
					// unsubscribe delete
					this.api.remove('extension/' + model.id + '/sip', null).then(function (r) {
						self.notify.info(r.message);
						if (r.status === 'success') {
							self.cache.setCache('extension', model);
							self.set('edit', false);
							if (typeof model.save !== 'undefined') {
								model.save();
							}
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
				} else if (!cached.sipSubscribed && model.sipSubscribed) {
					//subscribe post

					this.api.create('extension/' + model.id + '/sip', { data: JSON.stringify(data) }).then(function (r) {
						self.notify.info(r.message);
						if (r.status === 'success') {
							self.cache.setCache('extension', model);
							self.set('edit', false);
							if (typeof model.save !== 'undefined') {
								model.save();
							}
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
				} else if (cached.sipSubscribed && model.sipSubscribed) {
					// update put status, number

					this.api.save('extension/' + model.id + '/sip', null, { data: JSON.stringify(data) }).then(function (r) {
						self.notify.info(r.message);
						if (r.status === 'success') {
							self.cache.setCache('extension', model);
							self.set('edit', false);
							if (typeof model.save !== 'undefined') {
								model.save();
							}
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
				}
			}
		}
	});
});