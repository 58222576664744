define('client/router', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _clientConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _clientConfigEnvironment['default'].locationType
  });

  exports['default'] = Router.map(function () {
    this.route('login');
    this.route('profile');
    this.route('home');
    this.route('logout');
    this.route('voiceports', function () {
      this.route('create', function () {
        this.route('bulk');
      });
      this.route('extension', {
        path: ':id'
      }, function () {
        this.route('telefon', function () {
          this.route('buttons');
          this.route('forwarding');
        });
        this.route('team');
        this.route('messaging');
        this.route('sip');
        this.route('user');
      });
    });
    this.route('password');
    this.route('reset');
    this.route('pickupgroups', function () {
      this.route('pickupgroup', {
        path: ':id'
      }, function () {
        this.route('extensions');
      });
      this.route('create');
    });
    this.route('loading');
    this.route('error', { path: "*path" });
    this.route('reports');
    this.route('task', { path: "/task/confirm/:token" });
    this.route('queue');
  });
});