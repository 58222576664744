define('client/initializers/i18n', ['exports', 'cecc/initializers/i18n'], function (exports, _ceccInitializersI18n) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersI18n['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersI18n.initialize;
    }
  });
});