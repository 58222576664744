define('client/routes/voiceports/create/bulk', ['exports', 'ember', 'cecc/mixins/subdetail-route-mixin'], function (exports, _ember, _ceccMixinsSubdetailRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsSubdetailRouteMixin['default'], {
		afterModel: function afterModel(model /*, transition*/) {
			var self = this;
			this.api.query('location').then(function (r) {
				self.controllerFor('voiceports.create.bulk').set('locations', r.results);
			});
			this._super(model);
		}
	});
});