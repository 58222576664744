define('client/components/x-toggle', ['exports', 'ember-cli-toggle/components/x-toggle/component', 'client/config/environment'], function (exports, _emberCliToggleComponentsXToggleComponent, _clientConfigEnvironment) {

  var config = _clientConfigEnvironment['default']['ember-cli-toggle'] || {};

  exports['default'] = _emberCliToggleComponentsXToggleComponent['default'].extend({
    theme: config.defaultTheme || 'default',
    offLabel: config.defaultOffLabel || 'Off',
    onLabel: config.defaultOnLabel || 'On',
    showLabels: config.defaultShowLabels || false,
    size: config.defaultSize || 'medium'
  });
});