define('client/initializers/ember-notify', ['exports', 'cecc/initializers/ember-notify'], function (exports, _ceccInitializersEmberNotify) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersEmberNotify['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersEmberNotify.initialize;
    }
  });
});