define('client/app', ['exports', 'ember', 'client/resolver', 'ember-load-initializers', 'client/config/environment'], function (exports, _ember, _clientResolver, _emberLoadInitializers, _clientConfigEnvironment) {

	var App = undefined;

	_ember['default'].MODEL_FACTORY_INJECTIONS = true;

	App = _ember['default'].Application.extend({
		modulePrefix: _clientConfigEnvironment['default'].modulePrefix,
		podModulePrefix: _clientConfigEnvironment['default'].podModulePrefix,
		Resolver: _clientResolver['default']
	});

	(0, _emberLoadInitializers['default'])(App, _clientConfigEnvironment['default'].modulePrefix);

	exports['default'] = {
		create: function create() {

			var options = {
				type: 'GET'
			};

			if (_clientConfigEnvironment['default'].withCredentials) {
				options.xhrFields = {
					withCredentials: true
				};
			}

			_ember['default'].$.ajax(_clientConfigEnvironment['default'].apiUrl + 'user/me', options).then(function (r) {
				App = App.extend({
					init: function init() {
						this._super.apply(this, arguments);
						var app = this.__deprecatedInstance__;

						var data = app.lookup('service:data');
						var i18n = app.lookup('service:i18n');
						var cookie = Cookies.get('lang');
						var lang = cookie || _clientConfigEnvironment['default'].i18n.defaultLocale;

						data.set('lang', lang);
						i18n.set('locale', lang);
						moment.locale(lang);
						numeral.language(lang);
						data.set('user', r.results);
						data.set('overlay', 'layouts/login');
						data.set('online', true);
					}
				});
				App.create();
			}).fail(function () {
				App = App.extend({
					init: function init() {
						this._super.apply(this, arguments);
						var app = this.__deprecatedInstance__;

						var data = app.lookup('service:data');
						var i18n = app.lookup('service:i18n');
						var cookie = Cookies.get('lang');
						var lang = cookie || _clientConfigEnvironment['default'].i18n.defaultLocale;

						data.set('lang', lang);
						i18n.set('locale', lang);
						moment.locale(lang);
						numeral.language(lang);
						data.set('user', false);
						data.set('overlay', 'layouts/main');
						data.set('online', false);
					}
				});
				App.create();
			});
		}
	};
});