define('client/helpers/to-html', ['exports', 'cecc/helpers/to-html'], function (exports, _ceccHelpersToHtml) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersToHtml['default'];
    }
  });
  Object.defineProperty(exports, 'toHtml', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersToHtml.toHtml;
    }
  });
});