define('client/initializers/api', ['exports', 'cecc/initializers/api'], function (exports, _ceccInitializersApi) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersApi['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersApi.initialize;
    }
  });
});