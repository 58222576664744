define("client/helpers/extension-type", ["exports", "ember"], function (exports, _ember) {
    exports.extensionType = extensionType;

    function extensionType(params /*, hash*/) {
        var type = params[0];
        var DeskPhones = ["1603", "1608", "1616", "4610", "4612CL", "4620", "4621", "6408", "6408D+", "9608", "9608SIP", "9608SIPCC", "9611", "9611SIP", "9620", "9621", "9620SIP", "9630", "9630SIP", "9640", "9640SIP", "9641", "9641SIP", "9650", "9650SIP", "9408"];
        var Analog = ["2500", "CallrID"];
        var Vdn = ["vdn"];
        var Agent = ["agent"];
        var UM = ["um"];
        var xmobile = ["XMOBILE"];
        var out;

        if (DeskPhones.indexOf(type) !== -1) {
            out = 'DeskPhone';
        } else if (Analog.indexOf(type) !== -1) {
            out = 'Analog';
        } else if (Vdn.indexOf(type) !== -1) {
            out = 'Virtual';
        } else if (Agent.indexOf(type) !== -1) {
            out = 'Agent';
        } else if (UM.indexOf(type) !== -1) {
            out = 'Unified Messaging';
        } else if (xmobile.indexOf(type) !== -1) {
            out = 'DECT';
        } else {
            out = type;
        }

        return new _ember["default"].String.htmlSafe(out);
    }

    exports["default"] = _ember["default"].Helper.helper(extensionType);
});