define('client/controllers/voiceports/extension/user', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
		root: 'voiceports',
		resource: 'extension',
		rollback: true,
		actions: {
			save: function save() {
				var self = this;
				var model = this.get('model');
				var data = {
					user_id: model.user_id
				};

				if (model.user_id === null) {
					this.api.remove('extension/' + model.id + '/user', null).then(function (r) {
						self.notify.info(r.message);
						self.set('model.users', {});
						self.cache.setCache('extension', model);
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
					this.set('edit', false);
					return;
				}

				this.api.save('extension/' + model.id + '/user', null, { data: JSON.stringify(data) }).then(function (r) {
					self.notify.info(r.message);
					self.set('model.user_id', model.user_id);
					self.cache.setCache('extension', model);

					if (typeof model.save !== 'undefined') {
						model.save();
					}

					_ember['default'].$.each(self.users, function (k, v) {
						if (v.id === model.user_id) {
							self.set('model.users', v);
						}
					});
				})['catch'](function (r) {
					self.notify.error(r.message);
				});

				this.set('edit', false);
			}
		}
	});
});