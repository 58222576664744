define('client/controllers/voiceports', ['exports', 'ember', 'cecc/mixins/list-controller-mixin'], function (exports, _ember, _ceccMixinsListControllerMixin) {
    exports['default'] = _ember['default'].Controller.extend(_ceccMixinsListControllerMixin['default'], {
        //queryParams: ['sort','page','filter','query','project','location','deskphone','analog','virtual','agent','messaging','ec500','sip'],
        includes: ['locations', 'pickupgroups', 'users'],
        orderDirection: 'asc',
        resource: 'extension',
        preFilter: (function () {
            if (window.location.href.indexOf('my.') == -1) {
                return { active: { eq: 1, op: "AND" } };
            }
            return { active: { eq: 1, op: "AND" }, user_id: { eq: this.data.user.id, op: "AND" } };
        }).property(),
        isMyPortal: (function () {
            if (window.location.href.indexOf('my.') != -1) {
                return true;
            }
            return false;
        }).property(),
        fields: ['id', 'name', 'type', 'location_prefix', 'extension', 'locations.title'],
        project: false,
        location: false,

        deskphone: true,
        analog: true,
        virtual: true,
        agent: true,
        xmobile: true,
        meetme: true,
        huntgroup: true,
        wcbri: true,

        messaging: false,
        ec500: false,
        sip: false,
        actions: {
            toggleFilter: function toggleFilter() {
                this.set('showFilter', !this.get('showFilter'));
            },
            resetFilterQuery: function resetFilterQuery() {
                this.set('project', false);
                this.set('location', false);

                this.set('deskphone', true);
                this.set('analog', true);
                this.set('virtual', true);
                this.set('agent', true);
                this.set('xmobile', true);
                this.set('meetme', true);
                this.set('huntgroup', true);
                this.set('wcbri', true);

                this.set('showFilter', false);
                this.send('resetFilter');
            },
            filterQuery: function filterQuery() {
                var query = {};

                query.active = 1;

                if (this.get('project')) {
                    query.project_id = this.get('project');
                }

                if (this.get('location')) {
                    query.location_prefix = this.get('location');
                }

                if (this.get('deskphone') || this.get('analog') || this.get('virtual') || this.get('xmobile') || this.get('agent') || this.get('huntgroup') || this.get('wcbri') || this.get('meetme')) {
                    var type = [];

                    if (this.get('deskphone')) {
                        type = type.concat(["1603", "1608", "1616", "4610", "4612CL", "4620", "4621", "6408", "6408D+", "9608", "9608SIP", "9608SIPCC", "9611", "9611SIP", "9620", "9621", "9620SIP", "9630", "9630SIP", "9640", "9640SIP", "9641", "9641SIP", "9650", "9650SIP", "9408"]);
                    }

                    if (this.get('analog')) {
                        type = type.concat(["2500", "CallrID"]);
                    }

                    if (this.get('virtual')) {
                        type = type.concat(["vdn", "virtual", "Virtual"]);
                    }

                    if (this.get('xmobile')) {
                        type = type.concat(["XMOBILE"]);
                    }

                    if (this.get('agent')) {
                        type = type.concat(["agent"]);
                    }

                    if (this.get('huntgroup')) {
                        type = type.concat(["huntgroup"]);
                    }

                    if (this.get('wcbri')) {
                        type = type.concat(["wcbri"]);
                    }

                    if (this.get('meetme')) {
                        type = type.concat(["meetme"]);
                    }

                    query.type = { "in": type };
                }

                if (this.get('deskphone')) {
                    if (this.get('messaging')) {
                        query.messagingSubscribed = 1;
                    }

                    if (this.get('ec500')) {
                        query.ec500Subscribed = 1;
                    }

                    if (this.get('sip')) {
                        query.sipSubscribed = 1;
                    }
                }

                this.set('page', 1);
                this.set('filter', null);
                this.set('filterQuery', query);

                this.send('search');
            }
        }
    });
});