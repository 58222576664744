define('client/controllers/voiceports/create/bulk', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
		location_prefix: null,
		response: false,
		actions: {
			parse: function parse() {
				var self = this;
				var files = _ember['default'].$('#extensionImport')[0].files;
				var config = {
					header: true,
					complete: function complete(results /*, file*/) {
						self.set('response', results.data);
					},
					error: function error(_error, file) {
						console.log("ERROR:", _error, file);
					}
				};

				if (files.length > 0) {

					_ember['default'].$('#extensionImport').parse({
						config: config,
						before: function before(file /*, inputElem*/) {
							console.log("Parsing file:", file);
						},
						complete: function complete() {
							console.log("Done with all files.");
						}
					});
				}
			},
			upload: function upload() {
				var model = this.get('response');
				var location_prefix = this.get('location_prefix');
				var self = this;
				var len = model.length;

				_ember['default'].$.each(model, function (k, v) {

					if (!v.extension || !v.name) {
						self.notify.error('Spalte "extension" und/oder "name" sind nicht definiert.');
						return false;
					}

					if (!location_prefix || location_prefix.length + v.extension.length !== 6) {
						self.notify.error('Location Prefix + Extension Länge stimmen nicht überein.');
						return false;
					}

					var data = {
						id: location_prefix + '' + v.extension,
						extension: v.extension,
						name: v.name,
						location_prefix: location_prefix
					};

					self.api.create('extension', { data: JSON.stringify(data) }).then(function (r) {

						if (r.status === 'success') {
							self.get('response').removeObject(v);
						} else {
							_ember['default'].$('.ext-' + v.extension).css({ 'background-color': 'red', 'color': 'white' });
							_ember['default'].$('.ext-' + v.extension + ' div').attr('title', r.message).attr('data-toggle', 'tooltip');
						}

						if (len == k + 1) {
							self.notify.info('Import abgeschlossen');
						}
					})['catch'](function (r) {
						_ember['default'].$('.ext-' + v.extension).css({ 'background-color': 'red', 'color': 'white' });
						_ember['default'].$('.ext-' + v.extension + ' div').attr('title', r.responseJSON.message).attr('data-toggle', 'tooltip');

						if (len == k + 1) {
							self.notify.info('Import abgeschlossen');
						}
					});
				});
			},
			remove: function remove(item) {
				this.get('response').removeObject(item);
			}
		}
	});
});