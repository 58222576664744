define('client/initializers/float-label', ['exports', 'ember', 'ember-float-label/mixins/registrable'], function (exports, _ember, _emberFloatLabelMixinsRegistrable) {

  var alias = _ember['default'].computed.alias;

  exports['default'] = {
    name: 'float-label',

    initialize: function initialize() {
      _ember['default'].TextField.reopen(_emberFloatLabelMixinsRegistrable['default'], {
        floatLabel: alias('placeholder')
      });

      _ember['default'].TextArea.reopen(_emberFloatLabelMixinsRegistrable['default'], {
        floatLabel: alias('placeholder')
      });
    }
  };
});