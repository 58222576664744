define('client/routes/pickupgroups/create', ['exports', 'ember', 'cecc/mixins/detail-route-mixin'], function (exports, _ember, _ceccMixinsDetailRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_ceccMixinsDetailRouteMixin['default'], {
    model: function model() {
      return { name: '', project_id: null, location_prefix: null };
    },
    afterModel: function afterModel(model) {
      var self = this;
      this.api.query('project', {}, [], 1, 10000).then(function (r) {
        self.controllerFor('pickupgroups.create').set('projects', r.results);
        self.controllerFor('pickupgroups.create').set('hideprojects', r.results.length === 1 ? true : false);

        if (r.results.length === 1) {
          model.project_id = r.results[0].id;
          self.controllerFor('pickupgroups.create').set('model', model);
        }
      });

      this.api.query('location', {}, [], 1, 10000).then(function (r) {
        self.controllerFor('pickupgroups.create').set('locations', r.results);
      });
    }
  });
});