define('client/controllers/voiceports/extension/telefon/forwarding', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
		rollback: true,
		convert: function convert(model) {
			_ember['default'].$.each(model, function (k, v) {
				if (v === true) {
					model[k] = '1';
				} else if (v === false) {
					model[k] = '0';
				}
			});

			return model;
		},
		actions: {
			save: function save() {
				var model = this.get('model');
				var self = this;

				var compared = this.cache.compare('extension', model);
				compared.unconditionalInternal = model.unconditionalInternal;
				compared.unconditionalExternal = model.unconditionalExternal;

				this.api.save('extension', model.id, { data: JSON.stringify(this.convert(compared)) }).then(function (r) {
					self.notify.info(r.message);
					if (typeof model.save !== 'undefined') {
						model.save();
					}
					self.set('edit', false);
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			}
		}
	});
});