define('client/routes/voiceports/extension/team', ['exports', 'ember', 'cecc/mixins/subdetail-route-mixin'], function (exports, _ember, _ceccMixinsSubdetailRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsSubdetailRouteMixin['default'], {
		afterModel: function afterModel() {
			var self = this;
			this.api.query('pickupGroups', {}, [], 1, 10000).then(function (r) {
				self.controllerFor('voiceports.extension.team').set('pickupgroups', r.results);
			});
			this._super();
		}
	});
});