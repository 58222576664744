define('client/controllers/queue', ['exports', 'ember', 'cecc/mixins/list-controller-mixin'], function (exports, _ember, _ceccMixinsListControllerMixin) {
    exports['default'] = _ember['default'].Controller.extend(_ceccMixinsListControllerMixin['default'], {
        includes: [],
        filter: '',
        resource: 'job',
        preFilter: { status: { 'in': ['pending'] } },
        fields: ['arguments'],
        filterNames: [],
        filterQueries: {},
        actions: {}
    });
});