define('client/helpers/numeral', ['exports', 'cecc/helpers/numeral'], function (exports, _ceccHelpersNumeral) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersNumeral['default'];
    }
  });
  Object.defineProperty(exports, 'numeral', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersNumeral.numeral;
    }
  });
});