define('client/routes/logout', ['exports', 'ember', 'cecc/mixins/authentication'], function (exports, _ember, _ceccMixinsAuthentication) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsAuthentication['default'], {
		logout: function logout() {
			var self = this;
			this.api.find('logout').then(function () {
				self.data.set('overlay', 'layouts/main');
				self.data.set('user', false);
				self.data.set('online', false);
				//self.io.disconnect();
				self.transitionTo('login');
			})['catch'](function () {
				self.data.set('overlay', 'layouts/main');
				self.data.set('user', false);
				self.data.set('online', false);
				//self.io.disconnect();
				self.transitionTo('login');
			});
		},
		beforeModel: function beforeModel() {
			this.logout();
		},
		actions: {
			willTransition: function willTransition() {
				this.logout();
			}
		}
	});
});