define('client/initializers/login-modal', ['exports', 'cecc/initializers/login-modal'], function (exports, _ceccInitializersLoginModal) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersLoginModal['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersLoginModal.initialize;
    }
  });
});