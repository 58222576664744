define('client/helpers/from-now', ['exports', 'cecc/helpers/from-now'], function (exports, _ceccHelpersFromNow) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersFromNow['default'];
    }
  });
  Object.defineProperty(exports, 'fromNow', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersFromNow.fromNow;
    }
  });
});