define('client/controllers/voiceports/extension/messaging', ['exports', 'ember', 'cecc/mixins/subdetail-controller-mixin'], function (exports, _ember, _ceccMixinsSubdetailControllerMixin) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsSubdetailControllerMixin['default'], {
		queryParams: ['edit', 'edit2', 'ref'],
		edit2: false,
		rollback: true,
		toStr: function toStr(str) {
			if (str === true) {
				return '1';
			} else if (str === false) {
				return '0';
			}
		},
		convert: function convert(model) {
			_ember['default'].$.each(model, function (k, v) {
				if (v === true) {
					model[k] = '1';
				} else if (v === false) {
					model[k] = '0';
				}
			});

			return model;
		},
		isComplexVoicemail: (function () {
			var model = this.get('model');
			return model.ringsVoicemail == -1;
		}).property('model.ringsVoicemail'),
		ringsVoicemail: [{ id: 0, name: 'aus' }, { id: 1, name: '1' }, { id: 2, name: '2' }, { id: 3, name: '3' }, { id: 4, name: '4' }, { id: 5, name: '5' }, { id: 6, name: '6' }, { id: 7, name: '7' }, { id: 8, name: '8' }, { id: 9, name: '9' }],
		actions: {
			toggleEdit2: function toggleEdit2() {
				if (this.get('edit2') === true) {
					if (typeof this.get('model').rollback !== 'undefined' && this.get('rollback')) {
						this.get('model').rollback();
					}
				}
				this.set('edit2', !this.get('edit2'));
			},
			toggleMessaging: function toggleMessaging(isToggled) {
				var model = this.get('model');
				var cached = this.cache.getCache('extension');
				var self = this;

				if (isToggled && !cached.messagingSubscribed) {
					this.api.create('extension/' + model.id + '/messaging', null).then(function (r) {
						self.notify.info(r.message);
						self.cache.setCache('extension', model);
						if (typeof model.save !== 'undefined') {
							model.save();
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
						self.set('model.messagingSubscribed', false);
					});
				} else if (!isToggled && cached.messagingSubscribed) {
					return this.api.remove('extension/' + model.id + '/messaging', null).then(function (r) {
						self.notify.info(r.message);
						self.cache.setCache('extension', model);
						if (typeof model.save !== 'undefined') {
							model.save();
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
						self.set('model.messagingSubscribed', true);
					});
				}
			},
			saveOneNumber: function saveOneNumber() {
				var self = this;
				var model = this.get('model');
				var cached = this.cache.getCache('extension');
				var data = {
					data: JSON.stringify({ status: this.toStr(model.ec500status), number: model.ec500 })
				};

				if (cached.ec500Subscribed && !model.ec500Subscribed) {
					// unsubscribe delete
					this.api.remove('extension/' + model.id + '/oneNumber', null).then(function (r) {
						self.notify.info(r.message);
						self.cache.setCache('extension', model);
						self.set('edit', false);
						if (typeof model.save !== 'undefined') {
							model.save();
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
				} else if (!cached.ec500Subscribed && model.ec500Subscribed) {
					//subscribe post

					this.api.create('extension/' + model.id + '/oneNumber', this.convert(data)).then(function (r) {
						self.notify.info(r.message);
						self.cache.setCache('extension', model);
						self.set('edit', false);
						if (typeof model.save !== 'undefined') {
							model.save();
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
				} else if (cached.ec500Subscribed && model.ec500Subscribed) {
					// update put status, number

					this.api.save('extension/' + model.id + '/oneNumber', null, this.convert(data)).then(function (r) {
						self.notify.info(r.message);
						self.cache.setCache('extension', model);
						self.set('edit', false);
						if (typeof model.save !== 'undefined') {
							model.save();
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
				}
			},
			saveFax: function saveFax() {

				var self = this;
				var model = this.get('model');
				var cached = this.cache.getCache('extension');

				var data = {
					voiceFaxMail: model.voiceFaxMail,
					voiceFaxSender: model.voiceFaxSender
				};

				if (model.ringsVoicemail != "-1") {
					data.ringsVoicemail = model.ringsVoicemail;
				}

				if (model.pin && model.pin.length !== 0) {
					data.pin = model.pin;
				}

				if (cached.voiceFaxStatus && !model.voiceFaxStatus) {
					// unsubscribe delete
					this.api.remove('extension/' + model.id + '/voiceFax', null).then(function (r) {
						self.notify.info(r.message);
						self.cache.setCache('extension', model);
						self.set('edit2', false);
						if (typeof model.save !== 'undefined') {
							model.save();
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
				} else if (!cached.voiceFaxStatus && model.voiceFaxStatus) {
					//subscribe post

					this.api.create('extension/' + model.id + '/voiceFax', { data: JSON.stringify(this.convert(data)) }).then(function (r) {
						self.notify.info(r.message);
						self.cache.setCache('extension', model);
						self.set('edit2', false);
						if (typeof model.save !== 'undefined') {
							model.save();
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
				} else if (cached.voiceFaxStatus && model.voiceFaxStatus) {
					// update put status, number

					this.api.save('extension/' + model.id + '/voiceFax', null, { data: JSON.stringify(this.convert(data)) }).then(function (r) {
						self.notify.info(r.message);
						self.cache.setCache('extension', model);
						self.set('edit2', false);
						if (typeof model.save !== 'undefined') {
							model.save();
						}
					})['catch'](function (r) {
						self.notify.error(r.message);
					});
				}
			}
		}
	});
});