define('client/controllers/password', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		actions: {
			forgot: function forgot() {
				var self = this;

				this.api.post("user/forgotPassword", { mail: this.get('mail') }).then(function () {
					self.notify.error('Sie erhalten in Kürze eine E-Mail mit einem Link');
					self.transitionToRoute('login');
				})['catch'](function (r) {
					self.notify.error(r.message);
				});
			}
		}
	});
});