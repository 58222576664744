define('client/routes/reports', ['exports', 'ember', 'cecc/mixins/authentication'], function (exports, _ember, _ceccMixinsAuthentication) {
	exports['default'] = _ember['default'].Route.extend(_ceccMixinsAuthentication['default'], {
		model: function model() {
			return {};
		},
		afterModel: function afterModel() {
			var self = this;

			//chart

			this.api.find('callreport/home/acceptedAndMissed').then(function (r) {
				self.controllerFor('reports').set('chart', r.results);
			});
		}
	});
});