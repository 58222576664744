define('client/initializers/io', ['exports', 'cecc/initializers/io'], function (exports, _ceccInitializersIo) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersIo['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _ceccInitializersIo.initialize;
    }
  });
});