define('client/controllers/voiceports/extension', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        remove: false,
        isMyPortal: (function () {
            if (window.location.href.indexOf('my.') != -1) {
                return true;
            }
            return false;
        }).property(),
        isDeskPhone: (function () {
            var type = this.get('model.type');
            var DeskPhones = ["1603", "1608", "1616", "4610", "4612CL", "4620", "4621", "6408", "6408D+", "9608", "9608SIP", "9608SIPCC", "9611", "9611SIP", "9620", "9621", "9620SIP", "9630", "9630SIP", "9640", "9640SIP", "9641", "9641SIP", "9650", "9650SIP", "9408"];
            if (DeskPhones.indexOf(type) !== -1) {
                return true;
            }
            return false;
        }).property('model.type'),
        actions: {
            remove: function remove() {
                var self = this;
                var model = this.get('model');

                this.api.remove('extension', model.id).then(function (r) {
                    self.set('remove', false);
                    self.notify.info(r.message);
                    self.transitionToRoute('voiceports', { queryParams: { refresh: Date.now() } });
                })['catch'](function (r) {
                    self.notify.error(r.message);
                });
            },
            toggleRemove: function toggleRemove() {
                this.set('remove', !this.get('remove'));
            }
        }
    });
});