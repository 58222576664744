define('client/helpers/convert-number', ['exports', 'cecc/helpers/convert-number'], function (exports, _ceccHelpersConvertNumber) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersConvertNumber['default'];
    }
  });
  Object.defineProperty(exports, 'convertNumber', {
    enumerable: true,
    get: function get() {
      return _ceccHelpersConvertNumber.convertNumber;
    }
  });
});