define('client/controllers/pickupgroups/create', ['exports', 'ember', 'cecc/mixins/detail-controller-mixin'], function (exports, _ember, _ceccMixinsDetailControllerMixin) {
	exports['default'] = _ember['default'].Controller.extend(_ceccMixinsDetailControllerMixin['default'], {
		resource: 'pickupGroups',
		root: 'pickupgroups',
		actions: {
			create2: function create2() {
				var self = this;
				var model = this.get('model');
				model.id = model.location_prefix + '' + model.extension;
				var ex = model.extension;

				if (!ex.match(/^\d+$/)) {
					this.notify.error('Durchwahl darf nur aus Zahlen bestehen!');
					return;
				}

				if (ex.length > 3) {
					this.notify.error('Durchwahl darf maximal aus 3 Zahlen bestehen!');
					return;
				}

				this.api.create(this.get('resource'), { data: JSON.stringify(model) }).then(function (r) {
					if (r.status === "success") {
						self.notify.info(r.message);
						self.transitionToRoute('voiceports');
					} else {
						self.notify.error(r.message);
					}
				})['catch'](function (r) {
					self.notify.error(r.responseJSON.message);
				});
			}
		}
	});
});